import React from 'react';

export interface ISubmitButton {
  text: string;
}
const SubmitButton = ({ text }: ISubmitButton) => {
  return (
    <button
      type="submit"
      className="w-full xl:w-1/2 p-2 lg:p-4 my-3 lg:my-6 rounded-xl bg-primaryColor text-white font-medium font-poppins xl:text-xl"
    >
      {text}
    </button>
  );
};

export default SubmitButton;

import React, { useEffect } from 'react';
import { useAuth } from 'contexts/Authentication';
import { Logger } from 'services/logger';
import { useRouter } from 'next/dist/client/router';
import { InputTextGroup } from 'components/Forms/InputGroup';
import Image from 'next/image';
import Link from 'next/link';
import { Form } from '@unform/web';
import SubmitButton from 'components/atoms/buttons/SubmitButton';
import { showHandledErrors, showMessageStatus } from 'utils/SwalUtils';
import { GoogleIcon } from 'components/atoms/icons';

const logger = new Logger('Login');

export interface ILogin {
  returnUrl?: string;
}
const Login = ({ returnUrl }: ILogin) => {
  const router = useRouter();
  const { signInWithEmailAndPassword, signInWithOAuth, signOut } = useAuth();

  useEffect(() => {
    signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function handleLogin(data): Promise<void> {
    signInWithEmailAndPassword(data)
      .then(() => {
        if (returnUrl) {
          router.push(returnUrl);
        } else {
          router.push('/app');
        }
      })
      .catch(err => {
        if (err.code === 'auth/wrong-password') {
          return showMessageStatus({
            imageSrc: '/images/error-400.svg',
            titleContent: 'Ops, senha incorreta',
            textCancelButton: 'Tentar outra vez',
            textButton: 'Recuperar senha',
            textContent: `Parece que você digitou sua senha errado, tente novamente ou recupere sua senha.`,
            customClass: 'font-poppins',
            onIsAccepted: () => router.push('/recover'),
          });
        }
        if (err.code === 'auth/user-not-found') {
          return showMessageStatus({
            imageSrc: '/images/error-400.svg',
            titleContent: 'Ops, usuário não existe',
            textCancelButton: 'Tentar outra vez',
            textContent: `Seu e-mail está incorreto ou o usuário não existe, tente novamente...`,
            customClass: 'font-poppins',
          });
        }
      });
  }
  return (
    <div className="flex flex-col justify-center items-center w-full h-screen overflow-hidden bg-white">
      <div className="flex flex-col items-center px-8 py-8 h-full w-full max-w-screen-md">
        <div className="flex flex-col items-center justify-center w-full h-full">
          <div className="flex items-center justify-center w-full">
            <Link href="/">
              <Image
                src="/branding/logo-orange-menobel.png"
                width={211.5}
                height={46.5}
                layout="fixed"
                className="object-contain cursor-pointer"
                alt="Logo Menobel"
              />
            </Link>
          </div>
          <Form onSubmit={handleLogin} className="w-full mt-8 xl:w-2/3">
            <label className="mb-2 ml-2 font-medium text-secundaryColor lg:text-xl">
              E-mail *
            </label>
            <InputTextGroup
              name="email"
              type="email"
              className="p-2 sm:p-3 lg:p-4 w-full text-secundaryColor bg-primaryColor rounded-lg bg-opacity-10 lg:text-xl"
              placeholder="Digite seu e-mail"
              required={true}
            />
            <br />
            <label className="mb-2 ml-2 font-medium text-secundaryColor lg:text-xl ">
              Senha *
            </label>
            <InputTextGroup
              name="password"
              type="password"
              className="p-2 sm:p-3 lg:p-4 w-full text-secundaryColor bg-primaryColor rounded-lg bg-opacity-10 lg:text-xl"
              placeholder="Digite sua senha"
              required={true}
              minLength={6}
            />

            <div className="flex w-full justify-center items-center">
              <SubmitButton text="Entrar" />
            </div>

            <div className="flex flex-col items-center justify-center w-full my-2 lg:my-0">
              <div className="px-8 py-1 sm:py-2 lg:py-8 flex flex-col items-center">
                <button
                  type="button"
                  onClick={() => {
                    signInWithOAuth().then(() => {
                      showMessageStatus({
                        imageType: 'success',
                        titleContent: 'Uhuuu, Sucesso !!',
                        textButton: 'Vamos lá',
                        textContent: `Vamos prosseguir para o próximo passo ?`,
                        customClass: 'font-poppins',
                        onIsAccepted: () => router.push('/app'),
                      }).catch(err => {
                        showHandledErrors(err, {
                          showUnhandledModal: true,
                        }).catch(() => {
                          //
                        });
                      });
                    });
                  }}
                  className="p-1 sm:p-2 lg:p-2 bg-white flex items-center text-base font-poppins font-medium rounded-sm shadow-lg w-full justify-center lg:text-xl "
                >
                  <GoogleIcon size={35} className="mr-4" />
                  Entrar com Google
                </button>
              </div>
              <Link
                href={{ pathname: '/c/cadastrar-se', query: { returnUrl } }}
              >
                <a className="py-2 lg:py-4 font-light text-secundaryColor lg:text-base font-poppins">
                  Ainda não tem uma conta ?{' '}
                  <span className="font-semibold">Cadastrar-se</span>
                </a>
              </Link>
              <Link
                href={{ pathname: '/p/cadastrar-se', query: { returnUrl } }}
              >
                <a className="py-2 lg:py-4 font-light text-base text-secundaryColor lg:text-base font-poppins">
                  Quer ser um Nober ?{' '}
                  <span className="font-semibold">Cadastrar-se</span>
                </a>
              </Link>
              <Link href={{ pathname: '/recover' }}>
                <a className="py-2 lg:py-4 font-light text-base text-secundaryColor lg:text-base font-poppins">
                  Esqueceu sua senha?
                </a>
              </Link>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;

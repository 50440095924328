import React from 'react';
import { useRouter } from 'next/dist/client/router';
import Login from 'components/organisms/Authentication/Login';

const LoginPage = () => {
  const router = useRouter();
  const { returnUrl } = router.query;
  return <Login returnUrl={returnUrl as string} />;
};

export default LoginPage;
